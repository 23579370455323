import React, { ChangeEvent, useEffect, useState } from 'react';
import { Grid, ListItemIcon, ListItemText, MenuItem } from '@mui/material';
import { useFormik, Form, FormikProvider } from 'formik';
import { UserContactModel, ContactTypeLabels, ContactType } from '../../typings';
import { UserContactSchema } from '../../validationSchemes';
import { EntityEditDialog, SelectInput, TextInput, DeleteIcon, PhoneNumberInput } from '@lp/lp-ui';
import {
    createUserContact, updateUserContact, deleteUserContact,
    DeleteUserContactRequest, CreateUserContactRequest, UpdateUserContactRequest
} from '../../app/userSlice'
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { NewUserContactModel } from '../../functions';
import { useCurrentUser } from '../../app/useCurrentUser';

type ContactDialogProps = {
    open: boolean,
    data: UserContactModel,
    handleClose: () => void
}

const ContactDialog: React.FC<ContactDialogProps> = ({ open, data, handleClose }: ContactDialogProps) => {
    const dispatch = useAppDispatch();
    const userState = useAppSelector(store => store.user);
    const isNew = data.id === 0;
    const [isAdmin, _] = useCurrentUser();
    const [contactTypeSelected, setContactTypeSelected] = useState<ContactType>(data.contactType);

    useEffect(() => {
        setContactTypeSelected(data.contactType);
    }, [data]);

    const formik = useFormik<UserContactModel>({
        initialValues: data
            ? { ...data, phoneExtension: data.phoneExtension !== undefined ? data.phoneExtension : "" }
            : NewUserContactModel(),
        enableReinitialize: true,
        validationSchema: UserContactSchema,
        onSubmit: async (values) => {
            await handleSubmit(values);
        }
    });

    const {
        errors,
        touched,
        values,
        getFieldProps,
        submitForm,
        handleChange
    } = formik;

    const handlePopupClose = () => {
        formik.resetForm();
        handleClose();
    }

    const handleDelete = () => {
        const request: DeleteUserContactRequest = {
            userId: userState.id,
            contactId: data.id
        };
        dispatch(deleteUserContact(request));
        handlePopupClose();
    }

    const handleSubmit = async (contact: UserContactModel) => {
        if (isNew) {
            const request: CreateUserContactRequest = {
                userId: userState.id,
                contact: contact
            };
            const dispatchResult = await dispatch(createUserContact(request));
            if (dispatchResult.meta.requestStatus === "fulfilled") {
                handlePopupClose();
            }
        }
        else {
            const request: UpdateUserContactRequest = {
                userId: userState.id,
                contactId: data.id,
                contact: contact
            };
            const dispatchResult = await dispatch(updateUserContact(request));
            if (dispatchResult.meta.requestStatus === "fulfilled") {
                handlePopupClose();
            }
        }
    };

    const onContactTypeChanged = (e: React.ChangeEvent<any>) => {
        handleChange(e);
        setContactTypeSelected(e.target.value);
    }

    return (
        <React.Fragment>
            <FormikProvider value={formik}>
                <Form autoComplete="off" noValidate onSubmit={submitForm}>
                    <EntityEditDialog
                        open={open}
                        editing={isAdmin && (userState?.data?.enabled ?? false)}
                        maxWidth="sm"
                        fullWidth
                        title="Contact"
                        handleClose={handlePopupClose}
                        handleSave={submitForm}
                        showMoreMenu={!isNew && isAdmin && (userState?.data?.enabled ?? false)}
                        menuItems={[
                            <MenuItem key="menu-item-i" onClick={handleDelete}>
                                <ListItemIcon>
                                    <DeleteIcon />
                                </ListItemIcon>
                                <ListItemText>
                                    Delete
                                </ListItemText>
                            </MenuItem>
                        ]}
                    >
                        <Grid container spacing={0} columnSpacing={6}>
                            <Grid item xs={12}>
                                <SelectInput
                                    label="Type"
                                    isEditing={isAdmin && (userState?.data?.enabled ?? false)}
                                    required
                                    {...getFieldProps("contactType")}
                                    options={ContactTypeLabels}
                                    onChange={onContactTypeChanged}
                                    error={Boolean(touched.contactType && errors.contactType)}
                                    helperText={touched.contactType && errors.contactType}
                                    value={ContactTypeLabels.find(ctt => ctt.value === values.contactType)?.value}
                                />
                            </Grid>
                            <Grid item xs={contactTypeSelected === ContactType.Phone ? 9 : 12}>
                                {contactTypeSelected === ContactType.Email &&
                                    <TextInput
                                        isEditing={true}
                                        required
                                        type="string"
                                        label="Contact"
                                        {...getFieldProps("contact")}
                                        error={Boolean(touched.contact && errors.contact)}
                                        helperText={touched.contact && errors.contact}
                                    />
                                }
                                {contactTypeSelected !== ContactType.Email &&
                                    <PhoneNumberInput
                                        isEditing={true}
                                        defaultCountry="US"
                                        label="Contact"
                                        {...getFieldProps("contact")}
                                        onChange={(e: string | ChangeEvent<any>) => { formik.setFieldValue("contact", e); } }
                                        error={Boolean(touched.contact && errors.contact)}
                                        helperText={touched.contact && errors.contact}
                                    />
                                }
                            </Grid>
                            {contactTypeSelected === ContactType.Phone &&
                                <Grid item xs={3}>
                                    <TextInput
                                        isEditing={isAdmin && (userState?.data?.enabled ?? false)}
                                        type="string"
                                        label="Extension"
                                        inputProps={{ maxLength: 3 }}
                                        {...getFieldProps("phoneExtension")}
                                        error={Boolean(touched.phoneExtension && errors.phoneExtension)}
                                        helperText={touched.phoneExtension && errors.phoneExtension}
                                    />
                                </Grid>
                            }
                        </Grid>
                    </EntityEditDialog>
                </Form>
            </FormikProvider>
        </React.Fragment>
    );
}

export default ContactDialog;
